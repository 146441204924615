import { createAction, props } from '@ngrx/store';

import { PaymentTerm } from '../../models';

export const loadRequest = createAction('[Payment Terms] Load Request');

export const loadSuccess = createAction(
  '[Payment Terms] Load Success',
  props<{ items: PaymentTerm[] }>(),
);

export const loadFailure = createAction(
  '[Payment Terms] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Payment Terms] Reset State');
