import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PaymentTermsStoreEffects } from './effects';
import { paymentTermsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('paymentTerms', paymentTermsReducer),
    EffectsModule.forFeature([PaymentTermsStoreEffects]),
  ],
})
export class PaymentTermsStoreModule {}
